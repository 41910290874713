.wrapper {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 50vh 50vh;
  }
  
  .main {
    grid-column: 1 / -1;
    grid-row: 1/-1;
    background-image: url("../../images/bgGif4.gif");
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .login_content {
    grid-column: 9 / -1;
    grid-row: 1/2;
    /* background-color: green; */
  }
  
  @media all and (max-width: 650px) {
    .main {
      grid-column: 1 / -1;
      grid-row: 1/2;
    }
  
    .login_content {
      grid-column: 1 / -1;
      grid-row: 2/-1;
    }
  }
  