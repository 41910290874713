.app-bar{
    display: flex;
    height: 64px;
    padding-left: 20px;
    padding-right: 10px;
    background-color: #1976d2ff;
    /* box-shadow: 5px 5px lightblue; */
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.btn{
    display: flex;
    justify-content: end;
}