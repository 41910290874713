.main-container{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}



.container{
    /* flex: 1; */
    /* min-height: 100px; */
    /* flex-grow: 1; */

    position: fixed;
    top:64;
    left: 0;
    right: 0;

    padding: 10px;
    
    background-color: #1976d2ff;
    /* flex-direction: column; */
    
}

.btn-container{
  
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn-x{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    border-radius: 5px;
    background-color: #fff;
}

.middle-box{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #fff;
    height: 42px;
    border-radius: 5px;
}

.info-container{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    background-color: #fff;
    box-shadow: 2px 2px #555;
    border-radius: 5px;
}

.header{
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
}

.list-container{
    /* width: 100%; */
    display: flex;
    flex: 1;
    padding: 10px;
    max-height: calc(100vh - 146px);
    overflow-y: auto;
   
   
    
}

/* .bal{
    max-height: 590px;
    overflow-y: auto;
} */

.list-container .list-item{
   width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list-item .left-day{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1976d2ff;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-left: 10px;
}

.list-item  .right-content{
    display: flex;
    align-items: center;
    justify-content: center;
}

.monthly-data{
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 6fr 6fr;
    row-gap: 0.4rem;
    width: 100%;
}

.monthly-data p:nth-child(even){
    font-size:  0.8rem;
    text-align: end;
    font-weight: normal;
    margin-right: 15px;
}

.monthly-data p:nth-child(odd){
    font-size:  0.8rem;
    text-align: start;
    text-transform: uppercase;
    font-weight: bold;
}

.progress-container{
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

/* .right-content{
    display: flex;
    justify-content: center;
    align-items: center;
} */

@media all and (max-height: 720px) {
    .bal{
        max-height: 440px;
    }
    
  }