

.main_box{
  
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 5fr 7fr;
    row-gap: 0.4rem;
}

.title_field{
    text-transform: uppercase;
    font-weight: bolder;
    font-size:  0.8rem;
    text-align: start
}

p{
    margin: 0;
}

.title_value{
    font-size:  0.8rem;
    text-align: end;
    font-weight: normal;
}

.gm-style-iw + div {display: none;}

/* .gm-style-iw-tc{
    display: none;
}

.gm-style-iw + div{ display: none; } 

.gm-style-iw + div + img{ display: none; }  */


@media all and (max-width: 650px) {
    .main_box {
        row-gap: 0.3rem;
    }
  
    .title_field {
        font-size:  0.5rem;
    }

    .title_value{
        font-size:  0.5rem;
    }
  }