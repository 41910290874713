.main-container{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.container{
    flex: 1;
    /* min-height: 100px; */
    /* flex-grow: 1; */
    /* background-color: #1976d2ff; */
    display: grid;
    grid-template-rows: 100px auto;
    /* flex-direction: column; */
    /* padding: 10px; */
}

.daily-info-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 10px;
    background-color: #1976d2ff;
}

/* //style={{width:100,height:100,marginLeft:10}} */

.daily-info-container .card{
    width: 80px;
    height: 100px;
    /* margin-left: 10px; */
}

/* style={{display:"flex",width:"100%",height:"100%",flexDirection:"column",alignItems:"center"}} */

.daily-info-container .card .daily-main{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    
}

/* style={{width:100,height:70,padding:5}} */

.daily-image-container{
   width: 80px;
   height: 80px;
   padding: 5px;
    
}

.daily-main-content{
    max-height: calc(100vh - 240px);
    overflow-y: auto;
}

